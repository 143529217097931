define("discourse/plugins/discourse-reactions/discourse/routes/user-notifications-reactions-received", ["exports", "discourse/routes/discourse", "discourse/plugins/discourse-reactions/discourse/models/discourse-reactions-custom-reaction"], function (_exports, _discourse, _discourseReactionsCustomReaction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class UserNotificationsReactionsReceived extends _discourse.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "templateName", "user-activity-reactions");
      _defineProperty(this, "controllerName", "user-activity-reactions");
      _defineProperty(this, "queryParams", {
        acting_username: {
          refreshModel: true
        },
        include_likes: {
          refreshModel: true
        }
      });
    }
    model(params) {
      return _discourseReactionsCustomReaction.default.findReactions("reactions-received", this.modelFor("user").get("username"), {
        actingUsername: params.acting_username,
        includeLikes: params.include_likes
      });
    }
    setupController(controller, model) {
      let loadedAll = model.length < 20;
      this.controllerFor("user-activity-reactions").setProperties({
        model,
        canLoadMore: !loadedAll,
        reactionsUrl: "reactions-received",
        username: this.modelFor("user").get("username"),
        actingUsername: controller.acting_username,
        includeLikes: controller.include_likes
      });
      this.controllerFor("application").set("showFooter", loadedAll);
    }
  }
  _exports.default = UserNotificationsReactionsReceived;
});